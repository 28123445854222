<template>
  <div class="all-height">
   <div class="">
      <v-card loader-height="2" :loading="loading" :disabled="loading">
        <v-card-text class="">
          <div>
            <div class="d-flex mb-0">
              <div class="d-flex align-center">
                <span class="display-1">{{formatedData.length}}</span>
                <span class="ml-2 line-1">Reporting Units<br/>Count</span>
              </div>
              <v-spacer></v-spacer>
              <v-btn class="shadow-off" icon @click="refreshData();"><v-icon>mdi-refresh</v-icon></v-btn>
            </div>

            <lb-datatable
              :headers="headers"
              :items="formatedData"
              :enableslot="['action', 'submitterFew', 'approverFew', 'regional_approverFew', 'managerFew']"
              :loading="loading"
              multiselectkey="_id"
              v-if="data.length > 0"
            >
              <template v-slot:additionalinfo="{ item, selected, selectall }">
                <v-tooltip bottom content-class="tooltip-bottom" v-if="$nova.hasRight('commentary', 'approve') || $nova.hasRight('commentary_permission_user', 'read')">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon class="shadow-off" @click="getDownloadAllAccesslist();"><v-icon>mdi-table-arrow-down</v-icon></v-btn>
                  </template>
                  <span>Download All Access Detail</span>
                </v-tooltip>
                <v-tooltip
                  bottom
                  content-class="tooltip-bottom"
                  v-if="selectall || selected.length > 0"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      color=""
                      @click="editData(item, 'bulk', selected)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Bulk Update Access</span>
                </v-tooltip>
                <div class="text-right" v-if="pendingrequestlist.length > 0">
                  <v-tooltip bottom content-class="tooltip-bottom">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mx-0 px-0" icon small v-bind="attrs" v-on="on" @click.stop="pendingdialog=true">
                        <v-icon>mdi-timer-sand</v-icon>
                      </v-btn>
                    </template>
                    <span>View pending approval</span>
                  </v-tooltip>
                </div>
                <v-tooltip bottom content-class="tooltip-bottom">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mx-0 px-0" icon small v-bind="attrs" v-on="on" @click.stop="editData(item, 'file', [])">
                      <v-icon>mdi-file-upload</v-icon>
                    </v-btn>
                  </template>
                  <span>Bulk RU level upload</span>
                </v-tooltip>
              </template>
              <template v-slot:submitterFew="{ item }">
                <div class="d-flex justify-center">
                  <v-tooltip bottom content-class="tooltip-bottom" v-for="(v,k) in item.submitterFew" :key="k">
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar size="35" class="mr-1" :style="`background-color:${v.color}`" v-bind="attrs" v-on="on">
                        {{v.code}}
                      </v-avatar>
                    </template>
                    <span>{{v.name}}</span>
                  </v-tooltip>
                  <v-avatar size="35" v-if="item.__submitterplus > 0" class="mr-1 border-on">
                    +{{item.__submitterplus}}
                  </v-avatar>
                </div>
              </template>
              <template v-slot:approverFew="{ item }">
                <div class="d-flex justify-center">
                  <v-tooltip bottom content-class="tooltip-bottom" v-for="(v,k) in item.approverFew" :key="k">
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar size="35" class="mr-1" :style="`background-color:${v.color}`" v-bind="attrs" v-on="on">
                        {{v.code}}
                      </v-avatar>
                    </template>
                    <span>{{v.name}}</span>
                  </v-tooltip>
                  <v-avatar size="35" v-if="item.__approverplus > 0" class="mr-1 border-on">
                    +{{item.__approverplus}}
                  </v-avatar>
                </div>
              </template>
              <template v-slot:regional_approverFew="{ item }">
                <div class="d-flex justify-center">
                  <v-tooltip bottom content-class="tooltip-bottom" v-for="(v,k) in item.regional_approverFew" :key="k">
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar size="35" class="mr-1" :style="`background-color:${v.color}`" v-bind="attrs" v-on="on">
                        {{v.code}}
                      </v-avatar>
                    </template>
                    <span>{{v.name}}</span>
                  </v-tooltip>
                  <v-avatar size="35" v-if="item.__regional_approverplus > 0" class="mr-1 border-on">
                    +{{item.__regional_approverplus}}
                  </v-avatar>
                </div>
              </template>
              <template v-slot:managerFew="{ item }">
                <div class="d-flex justify-center">
                  <v-tooltip bottom content-class="tooltip-bottom" v-for="(v,k) in item.managerFew" :key="k">
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar size="35" class="mr-1" :style="`background-color:${v.color}`" v-bind="attrs" v-on="on">
                        {{v.code}}
                      </v-avatar>
                    </template>
                    <span>{{v.name}}</span>
                  </v-tooltip>
                  <v-avatar size="35" v-if="item.__managerplus > 0" class="mr-1 border-on">
                    +{{item.__managerplus}}
                  </v-avatar>
                </div>
              </template>
              <template v-slot:action="{ item }">
                <v-tooltip
                  bottom
                  content-class="tooltip-bottom"
                  v-if="$nova.hasRight(right, 'edit')"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      color=""
                      @click="editData(item, 'single', item._id)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Update for Actual Cycle</span>
                </v-tooltip>
              </template>
            </lb-datatable>
            <div v-else class="title grey--text pa-5 text-center">
              No Data Found
            </div>
          </div>
        </v-card-text>
      </v-card>

      <lb-dialog v-model="addeditrudialog" heading="Update Access details" width="800" :loading="addeditruloading">
        <template v-slot:body>
          <div>
            <div class="subtitle-1 mb-2 font-weight-bold" v-if="!bulksubmit">
              <span>{{addeditrudata.code}}</span>
              <v-icon>mdi-circle-small</v-icon>
              <span>{{addeditrudata.name}}</span>
            </div>
            <div v-else>
              <div class="d-flex align-center pb-4">
                <span class="display-1">{{(addeditrudata._id || []).length}}</span>
                <span class="ml-2 line-1-2">Reporting units<br/>Selected</span>
              </div>
            </div>
            <div class="mb-3" v-if="!bulksubmit">
              <v-chip small label outlined class="pr-0 mr-2">
                <span>Network</span>
                <v-chip small label class="ml-2">{{addeditrudata.network}}</v-chip>
              </v-chip>
              <v-chip small label outlined class="pr-0 mr-2">
                <span>Region</span>
                <v-chip small label class="ml-2">{{addeditrudata.region}}</v-chip>
              </v-chip>
              <v-chip small label outlined class="pr-0 mr-2">
                <span>Country</span>
                <v-chip small label class="ml-2">{{addeditrudata.country}}</v-chip>
              </v-chip>
            </div>
            <div class="mb-1">
              <div class="">
                <span class="font-weight-bold">Prepares(s):</span> 
                <v-tooltip bottom content-class="tooltip-bottom">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="addusertype='submitter';adduserdialog=true;adduservalue=''" v-bind="attrs" v-on="on" icon small><v-icon>mdi-plus-circle</v-icon></v-btn>
                  </template>
                  <span>Add Preparer</span>
                </v-tooltip>
              </div>
              <div v-if="(addeditrudata.submitter || []).length > 0">
                <v-chip class="mr-1 mb-1 pr-0" small v-for="(v,k) in addeditrudata.submitter" 
                  :style="`background-color:${v.color}`" :key="k">
                  {{v.name}}
                  <v-btn small icon class="ml-1" @click="removeAccess(k, 'submitter')"><v-icon>mdi-close-circle</v-icon></v-btn>
                </v-chip>
              </div>
              <div v-else class="grey--text px-4">No user selected</div>
            </div>
            <div class="mb-1">
              <div class="">
                <span class="font-weight-bold">Approver(s):</span> 
                <v-tooltip bottom content-class="tooltip-bottom">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="addusertype='approver';adduserdialog=true;adduservalue=''" v-bind="attrs" v-on="on" icon small><v-icon>mdi-plus-circle</v-icon></v-btn>
                  </template>
                  <span>Add Approver</span>
                </v-tooltip>
              </div>
              <div v-if="(addeditrudata.approver || []).length > 0">
                <v-chip class="mr-1 mb-1 pr-0" small v-for="(v,k) in addeditrudata.approver" 
                  :style="`background-color:${v.color}`" :key="k">
                  {{v.name}}
                  <v-btn small icon class="ml-1" @click="removeAccess(k, 'approver')"><v-icon>mdi-close-circle</v-icon></v-btn>
                </v-chip>
              </div>
              <div v-else class="grey--text px-4">No user selected</div>
            </div>
            <div class="mb-1">
              <div class="">
                <span class="font-weight-bold">Regional Approver(s):</span> 
                <v-tooltip bottom content-class="tooltip-bottom">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="addusertype='regional_approver';adduserdialog=true;adduservalue=''" v-bind="attrs" v-on="on" icon small><v-icon>mdi-plus-circle</v-icon></v-btn>
                  </template>
                  <span>Add Regional Approver</span>
                </v-tooltip>
              </div>
              <div v-if="(addeditrudata.regional_approver || []).length > 0">
                <v-chip class="mr-1 mb-1 pr-0" small v-for="(v,k) in addeditrudata.regional_approver" 
                  :style="`background-color:${v.color}`" :key="k">
                  {{v.name}}
                  <v-btn small icon class="ml-1" @click="removeAccess(k, 'regional_approver')"><v-icon>mdi-close-circle</v-icon></v-btn>
                </v-chip>
              </div>
              <div v-else class="grey--text px-4">No user selected</div>
            </div>
            <div class="mb-1">
              <div class="">
                <span class="font-weight-bold">Manager(s):</span> 
                <v-tooltip bottom content-class="tooltip-bottom">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="addusertype='manager';adduserdialog=true;adduservalue=''" v-bind="attrs" v-on="on" icon small><v-icon>mdi-plus-circle</v-icon></v-btn>
                  </template>
                  <span>Add Manager</span>
                </v-tooltip>
              </div>
              <div v-if="(addeditrudata.manager || []).length > 0">
                <v-chip class="mr-1 mb-1 pr-0" small v-for="(v,k) in addeditrudata.manager" 
                  :style="`background-color:${v.color}`" :key="k">
                  {{v.name}}
                  <v-btn small icon class="ml-1" @click="removeAccess(k, 'manager')"><v-icon>mdi-close-circle</v-icon></v-btn>
                </v-chip>
              </div>
              <div v-else class="grey--text px-4">No user selected</div>
            </div>
            <lb-divider class="mt-3 mb-2"></lb-divider>
            <div>
              <div class="subtitle-1 text-center" v-if="[...userdatachange_submitter, ...userdatachange_approver, ...userdatachange_regional_approver, ...userdatachange_manager].length === 0">No changes done yet</div>
              <div v-else>
                <div class="d-flex align-center">
                  <div class="subtitle-1">Changes</div>
                  <v-spacer></v-spacer>
                  <v-chip outlined class="border-left-error" small>
                    Removed
                  </v-chip>
                  <v-chip outlined class="ml-2 border-left-success" small>
                    Added
                  </v-chip>
                </div>
                <div v-if="(userdatachange_submitter || []).length > 0">
                  <div class="font-weight-bold">Preparer(s):</div> 
                  <template v-for="(v,k) in (userdatachange_submitter || [])">
                    <v-chip :class="`mr-1 mb-1 pr-0 ${v.action==='remove'?'border-left-error':'border-left-success'}`" small :style="`background-color:${v.user.color}`" :key="k">
                      {{v.user.name}}
                      <v-btn small icon class="ml-1" @click="undoChange(v.user?._id, k, 'submitter', v.action)"><v-icon>mdi-close-circle</v-icon></v-btn>
                    </v-chip>
                  </template>
                </div>
                <div v-if="(userdatachange_approver || []).length > 0">
                  <div class="font-weight-bold">Approver(s):</div> 
                  <template v-for="(v,k) in (userdatachange_approver || [])">
                    <v-chip :class="`mr-1 mb-1 pr-0 ${v.action==='remove'?'border-left-error':'border-left-success'}`" small :style="`background-color:${v.user.color}`" :key="k">
                      {{v.user.name}}
                      <v-btn small icon class="ml-1" @click="undoChange(v.user?._id, k, 'approver', v.action)"><v-icon>mdi-close-circle</v-icon></v-btn>
                    </v-chip>
                  </template>
                </div>
                <div v-if="(userdatachange_regional_approver || []).length > 0">
                  <div class="font-weight-bold">Regional Approver(s):</div> 
                  <template v-for="(v,k) in (userdatachange_regional_approver || [])">
                    <v-chip :class="`mr-1 mb-1 pr-0 ${v.action==='remove'?'border-left-error':'border-left-success'}`" small :style="`background-color:${v.user.color}`" :key="k">
                      {{v.user.name}}
                      <v-btn small icon class="ml-1" @click="undoChange(v.user?._id, k, 'regional_approver', v.action)"><v-icon>mdi-close-circle</v-icon></v-btn>
                    </v-chip>
                  </template>
                </div>
                <div v-if="(userdatachange_manager || []).length > 0">
                  <div class="font-weight-bold">Manager(s):</div> 
                  <template v-for="(v,k) in (userdatachange_manager || [])">
                    <v-chip :class="`mr-1 mb-1 pr-0 ${v.action==='remove'?'border-left-error':'border-left-success'}`" small :style="`background-color:${v.user.color}`" :key="k">
                      {{v.user.name}}
                      <v-btn small icon class="ml-1" @click="undoChange(v.user?._id, k, 'manager', v.action)"><v-icon>mdi-close-circle</v-icon></v-btn>
                    </v-chip>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn small color="primary" v-if="[...userdatachange_submitter, ...userdatachange_approver, ...userdatachange_regional_approver, ...userdatachange_manager].length > 0" @click="updateUserAccessChange()">Update</v-btn>
        </template>
      </lb-dialog>
    
      <v-dialog v-model="adduserdialog" max-width="400" persistent>
        <v-card class="">
          <v-card-text class="primary white--text d-flex align-center py-2 px-4">
            <span class="subtitle-1 white--text">Select a user</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="adduserdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-text>
          <v-card-text class="mt-4 pt-4">
            <v-row>
              <v-col cols="12" class="my-0 py-1">
                <lb-dropdown hidedetails v-model="adduservalue" :items="filteredUserListArr" itemvalue="_id" itemtext="newdisplay" label="Users"/>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="d-flex">
            <v-spacer></v-spacer>
            <v-btn small color="primary" @click="addUser()">Select</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <lb-dialog v-model="pendingdialog" heading="Pending approval list" width="800" :loading="pendingloading">
        <template v-slot:body>
          <div>
            <ApprovalsList :data="pendingrequestlist" @loading="setPendingLoading"/>
          </div>
        </template>
      </lb-dialog>

      <lb-dialog v-model="bulksubmitrudialog" heading="Bulk Upload RU Level Access" :width="(bulksubmitrufiledata.length > 0)?'1200':'500'" :loading="bulksubmitruloading">
        <template v-slot:body>
          <div>
            <div class="mb-2 d-flex align-center justify-center">
              <v-btn color="primary" small class="mr-2" @click="$nova.downloadFile('', {}, '/v2/varianceanalysis/reportingunit/getbulkrusubmittemplate')">Download Template</v-btn>
              <lb-file v-model="bulksubmitrufile" label="" class="py-0 my-0" :loading="fileprocessing['file'] === 1"
                :drag="false" hidedetails @change="readBulkSubmitFile('file')" :displayname="false" accept=".xlsx,.xls,.xlsm,.xlsb" />
            </div>
            <div v-if="bulksubmitrufiledata.length > 0">
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th scope="rucode" class="">RU Code</th>
                    <th scope="runame" class="">RU Name</th>
                    <th scope="l_preparer" class="">Local Preparer</th>
                    <th scope="l_approver" class="">Local Approver</th>
                    <th scope="r_approver" class="">Regional Approver</th>
                    <th scope="manager" class="">Manager</th>
                  </tr>
                </thead>
                <v-slide-y-transition class="" group tag="tbody" style="overflow:hidden !important;">
                  <tr v-for="(v, k) in bulksubmitrufiledata" :key="k" class="">
                    <template v-for="(vv, kk) in bulksubmitrufilecolumns">
                      <td :key="kk" class="">
                        <div v-if="['l_preparer', 'l_approver', 'r_approver', 'manager'].indexOf(vv) > -1">
                          <!-- {{(v[vv] || [])}}
                          {{bulksubmitrufileerror[k][vv][vk]}} -->
                          <template v-for="(nv, nk) in (v[vv] || [])">
                            <v-tooltip v-if="bulksubmitrufileerror[k][vv]?.[nk]" bottom content-class="tooltip-bottom" :key="nk">
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip small label outlined v-bind="attrs" v-on="on" class="error--text font-weight-bold mr-1 mb-1"><v-icon left>mdi-account</v-icon>{{nv || 'Error'}}</v-chip>
                              </template>
                              <span>{{ bulksubmitrufileerror[k][vv][nk] }}</span>
                            </v-tooltip>
                            <v-chip small label outlined v-else class="mr-1 mb-1" :key="nk"><v-icon left>mdi-account</v-icon>{{ nv || '' }}</v-chip>
                          </template>                          
                        </div>
                        <v-tooltip v-else-if="bulksubmitrufileerror[k][vv]" bottom content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" class="error--text font-weight-bold">{{v[vv] || 'Error'}}</span>
                          </template>
                          <span>{{ bulksubmitrufileerror[k][vv] }}</span>
                        </v-tooltip>
                        <div v-else>{{ v[vv] || '' }}</div>
                      </td>
                    </template>
                  </tr>
                </v-slide-y-transition>
              </v-simple-table>
            </div>
            <div v-else class="grey--text pa-6 text-center title">
              Upload template file
            </div>
          </div>
        </template>
        <template v-slot:actions>
          <div v-if="bulksubmitrufileerrorflag" class="error--text font-weight-bold">
            We found few errors. Please rectify the same to proceed with the upload
          </div>
          <!-- <span v-else-if="bulksubmitrufiledata.length > 0" class="caption">*Required</span> -->
          <v-spacer></v-spacer>
          <v-btn v-if="bulksubmitrufiledata.length > 0 && !bulksubmitrufileerrorflag" @click="updateUserAccessChange()" color="primary" small class="ml-2 shadow-off">Proceed to Upload</v-btn>
        </template>
      </lb-dialog>

      <lb-dialog v-model="allaccessdownloaddialog" :loading="allaccessdownloadloading" heading="All RU data download" width="600">
        <template v-slot:body>
          <div>
            <div class="d-flex align-center">
              <v-spacer/>
              <v-btn small color="success" @click="downloadAllRU()">Generate latest</v-btn>
            </div>
            <lb-divider class="my-4"/>
            <div v-if="allaccessdownloadlist.length === 0" class="title grey--text text--lighten-1 ma-4 text-center">No downloads</div>
            <div v-else>
              <div class="mb-2 font-weight-bold">Previous downloads</div>
              <div class="hoverable" v-for="(v,k) in allaccessdownloadlist" :key="k">
                <div class="d-flex align-center px-2 py-1">
                  <div>{{$nova.formatDate("created_at")}}</div>
                  <v-spacer/>
                  <v-tooltip bottom content-class="tooltip-bottom" v-if="v.stage===1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mx-0 px-0" icon small v-bind="attrs" v-on="on" @click.stop="downloadAllRU(v._id)">
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <span>Download</span>
                  </v-tooltip>
                  <v-chip small v-if="v.stage === 1" color="success" label>Ready</v-chip>
                  <v-chip small v-else-if="v.stage === 0 || v.stage === 4" color="info" label>Generating</v-chip>
                  <v-chip small v-else color="error" label>Error</v-chip>
                </div>
                <v-divider/>
              </div>
            </div>
          </div>
        </template>
      </lb-dialog>


    </div>
  </div>
</template>

<script>
import ApprovalsList from "./ApprovalsList.vue";
// import cfilter from './Filter'
export default {
  name: 'reportingunits',
  // props: ['props'],
  data: function(){
    return {
      right: "commentary_reportingunit",
      loading: false,
      data: [],
      userlist: {},
      userrulist: {},
      userlistarr: {},
      useremailidmap: {},
      addeditrudialog: false,
      addeditruloading: false,
      addeditrudata: {},
      userdatachange_submitter: [],
      userdatachange_approver: [],
      userdatachange_regional_approver: [],
      userdatachange_manager: [],
      addeditruerror: {},

      adduserdialog: false,
      addusertype: '',
      adduservalue: '',
      rucodenamemap: {},
      rucodeidmap: {},

      bulksubmitrudialog: false,
      bulksubmitruloading: false,
      bulksubmitrufileerrorflag: false,
      bulksubmitrufile: null,
      bulksubmitrufiledata: [],
      bulksubmitrufileerror: [],
      bulksubmitrufilecolumns: ["rucode", "runame", "l_preparer", "l_approver", "r_approver", "manager"],
      fileprocessing: {},

      bulksubmit: false,
      filesubmit: false,
      pendingrequestlist: [],
      pendingdialog: false,
      pendingloading: false,
      formatedData: [],
      headers: [
        {
          value: "indexno",
          text: "#",
          sortable: false,
          filterable: false,
        },
        {
          value: "code",
          text: "RU Code",
          sortable: true,
          filterable: true,
          lock: true,
        },
        {
          value: "name",
          text: "Name",
          sortable: true,
          filterable: true,
          lock: true,
        },
        {
          value: "network",
          text: "Network",
          sortable: true,
          filterable: true,
          lock: true,
        },
        {
          value: "region",
          text: "region",
          sortable: true,
          filterable: true,
          lock: true,
        },
        {
          value: "country",
          text: "Country",
          sortable: true,
          filterable: true,
          lock: true,
        },
        {
          value: "currency",
          text: "Currency",
          sortable: true,
          filterable: true,
          lock: false,
          visible: false,
        },
        {
          value: "submitterFew",
          text: "Preparer",
          sortable: false,
          filterable: false,
          lock: true,
          alignment: "text-center",
        },
        {
          value: "approverFew",
          text: "Approver",
          sortable: false,
          filterable: false,
          lock: true,
          alignment: "text-center",
        },
        {
          value: "regional_approverFew",
          text: "Regional Approver",
          sortable: false,
          filterable: false,
          lock: true,
          alignment: "text-center",
        },
        {
          value: "managerFew",
          text: "Manager",
          sortable: false,
          filterable: false,
          lock: true,
          alignment: "text-center",
        },
        {
          value: "created_at",
          text: "Created At",
          sortable: false,
          filterable: true,
          datatype: "date",
          lock: false,
          visible: false,
        },
        {
          value: "updated_at",
          text: "Updated At",
          sortable: false,
          filterable: true,
          datatype: "date",
          lock: false,
          visible: false,
        },
        {
          value: "action",
          text: "Action",
          sortable: false,
          datatype: "action",
          lock: true,
          visible: false,
          alignment: "text-center",
        },
      ],

      // selectedrole: "",
      // role: [],
      // rolelist: {},
      // selectedyear: "",
      // year: [],
      // yearlist: {},
      // selectedperiod: "",
      // period: [],
      // perioddata: [],
      // periodlist: {},
      // selectednetwork: "",
      // network: [],
      // networkdata: [],
      // networklist: {},
      // selectedregion: "",
      // region: [],
      // regiondata: [],
      // regionlist: {},
      // selectedcountry: "",
      // country: [],
      // countrydata: [],
      // countrylist: {},
      // selectedreportingunit: "",
      // reportingunit: [],
      // reportingunitdata: [],
      // reportingunitlist: {},
      allaccessdownloaddialog: false,
      allaccessdownloadloading: false,
      allaccessdownloadlist: [],
      allRUDownloadPeriod: null,

    }
  },
  components: {
    ApprovalsList
    // cfilter
  },
  activated() {
    this.refreshData();
  },
  created() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.getData();
    },
    setPendingLoading(v) {
      this.pendingloading = v;
    },
    getData() {
      this.loading = true;
      this.pendingrequestlist = [];
      this.axios.post("/v2/varianceanalysis/reportingunit/getalllist", {filter: {}}).then(dt => {
        if(dt.data.status === "success") {
          this.data = dt.data.data;
          this.formateData();
        }
        else throw new Error ("Error reading data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
        this.getPendingApprovalData();
      })
    },
    // getData() {
    //   this.loading = true;
    //   this.pendingrequestlist = [];
    //   this.axios.post("/v2/varianceanalysis/reportingunit/getformdata").then(dt => {
    //     if(dt.data.status === "success") {
    //       let ndt = dt.data.data[0] || {};
    //       this.userlist = {};
    //       this.userlistarr = ndt.users || [];
    //       let colors = this.$nova.getColors(this.userlistarr.length, 0.2);
    //       this.useremailidmap = {};
    //       for (let i = 0; i < this.userlistarr.length; i++) {
    //         const el = this.userlistarr[i];
    //         this.useremailidmap[el.email] = el._id;
    //         el.newdisplay = el.email;
    //         if(el.name) el.newdisplay + " - " + el.name;
    //         this.userlistarr[i].newdisplay = el.newdisplay;
    //         this.userlist[el._id] = el;
    //         this.userlist[el._id].color = colors[i];
    //       }
    //       return this.axios.post("/v2/varianceanalysis/reportingunit/getalllist", {filter: {}});
    //     }
    //     else throw new Error ("Error reading data");
    //   }).then(dt => {
    //     if(dt.data.status === "success") {
    //       this.data = dt.data.data;
    //       this.formateData();
    //     }
    //     else throw new Error ("Error reading data");
    //   }).catch(err => {
    //     this.$store.commit("sbError", err.message || err || "Unknown error!");
    //     console.log(err);
    //   }).finally(() => {
    //     this.loading = false;
    //     this.getPendingApprovalData();
    //   })
    // },
    getPendingApprovalData() {
      this.pendingrequestlist = [];
      this.axios.post("/v2/varianceanalysis/reportingunit/getpendingaccessrequest").then(dt => {
        if(dt.data.status === "success") {
          if(dt.data.data.length > 0) {
            this.pendingrequestlist = dt.data.data;
          }
        }
        else throw new Error ("Error reading pending request data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
      })
    },
    formateData() {
      let ArrayTemp = [];
      this.rucodenamemap = {};
      this.rucodeidmap = {};
      this.data.forEach((i, k) => {
        this.rucodenamemap[i.code] = i.name;
        this.rucodeidmap[i.code] = i._id;
        ArrayTemp.push({
          __key: k,
          __formatedkey: k,
          _id: i._id,
          indexno: k + 1,
          code: i.code,
          name: i.name,
          network: (i.network_detail || {}).name || "NA",
          region: (i.region_detail || {}).name || "NA",
          country: (i.country_detail || {}).name || "NA",
          submitterids: i.submitter || [],
          submitter: this.getCodeName(i.submitter || []),
          submitterFew: this.getCodeName(i.submitter || [], 2),
          __submitterplus: ((i.submitter || []).length - 2),
          approverids: i.approver || [],
          approver: this.getCodeName(i.approver || []),
          approverFew: this.getCodeName(i.approver || [], 2),
          __approverplus: ((i.approver || []).length - 2),
          regional_approverids: i.regional_approver || [],
          regional_approver: this.getCodeName(i.regional_approver || []),
          regional_approverFew: this.getCodeName(i.regional_approver || [], 2),
          __regional_approverplus: ((i.regional_approver || []).length - 2),
          managerids: i.manager || [],
          manager: this.getCodeName(i.manager || []),
          managerFew: this.getCodeName(i.manager || [], 2),
          __managerplus: ((i.manager || []).length - 2),
          currency: i.currency,
          created_at: i.created_at,
          updated_at: i.updated_at,
        });
      });
      // console.log(ArrayTemp)
      this.formatedData = ArrayTemp;
    },
    getCodeName(dt, limit){
      return dt.slice(0, limit || (dt || []).length).map(el => {return {
        _id: el,
        code: (((this.userlist[el] || {}).email || "UN").split("") || []).slice(0, 2).map((word) => word[0].toUpperCase()).join(""),
        name: (this.userlist[el] || {}).email || "Unknown User",
        color: (this.userlist[el] || {}).color || 'rgba(0,0,0,0.2)'
      }})
    },
    editData(v, type, ru) {
      // this.editablegls = [];
      // this.ruleveledit.local = false;
      // this.ruleveledit.regional = false;
      // this.ruleveledit.enable = false;
      this.userdatachange_submitter = [];
      this.userdatachange_approver = [];
      this.userdatachange_regional_approver = [];
      this.userdatachange_manager = [];
      if(type === "bulk") {
        this.bulksubmit = true;
        this.filesubmit = false;
        this.addeditrudata={};
        this.addeditrudata.__type = type;
        this.addeditruerror={};
        this.getUsers(ru);
      }
      else if(type === "single") {
        this.addeditrudata=this.$nova.deepCopyJson(v);
        this.addeditrudata.__originalsubmitter = [...(this.addeditrudata.submitter || [])];
        this.addeditrudata.__originalapprover = [...(this.addeditrudata.approver || [])];
        this.addeditrudata.__originalregional_approver = [...(this.addeditrudata.regional_approver || [])];
        this.addeditrudata.__originalmanager = [...(this.addeditrudata.manager || [])];
        this.addeditrudata.__type = type;
        this.addeditruerror={};
        this.bulksubmit = false;
        this.filesubmit = false;
        this.getUsers([ru]);
      }
      else if(type === "file") {
        this.bulksubmit = false;
        this.filesubmit = true;
        this.addeditrudata={};
        this.addeditrudata.__type = type;
        this.addeditruerror={};
        this.bulksubmitrudialog = true;
      }
      if(type === "bulk" || type === "single"){
        this.addeditrudata._id = ru;
        this.addeditrudialog=true;
        // if(ru instanceof Array) this.formattedGLData = this.generateFormattedGLList({});
        // else this.getRUData(ru);
      }
    },
    getDownloadAllAccesslist() {
      this.allaccessdownloaddialog = true;
      this.allaccessdownloadloading = true;
      let url = "/v2/varianceanalysis/reportingunit/downloadallaccessexcellist/";
      this.axios.post(url, {}).then(dt => {
        if(dt.data.status === "success") {
          this.allaccessdownloadlist = dt.data.data;
        }
        else throw new Error (dt.data.message || "Error loading download list");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.allaccessdownloadloading = false;
      })
    },
    downloadAllAccess(id) {
      this.allaccessdownloadloading = true;
      let url = "/v2/varianceanalysis/reportingunit/downloadallaccessexcel/";
      if(id) url = url + id;
      // console.log(this.selectedcommentary);
      this.axios.post(url, {filter: {}}).then(dt => {
        if(dt.data.status === "success") {
          this.getDownloadAllAccesslist();
          console.log(dt.data);
        }
        else throw new Error (dt.data.message || "Error generating download");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.allaccessdownloadloading = false;
      })
    },    
    updateUserAccessChange() {
      this.addeditruloading = true;
      let ru = this.addeditrudata._id;
      let newdata = {
        // reportingunit: this.addeditrudata._id || '',
      };
      if(this.addeditrudata.__type === 'single' || this.addeditrudata.__type === 'bulk'){
        if(this.bulksubmit) {
          newdata.bulk = true;
          newdata.type = "bulkaccesschange";
          let udta = [];
          let ndt = [...(this.userdatachange_submitter || []), ...(this.userdatachange_approver || []), ...(this.userdatachange_regional_approver || []), ...(this.userdatachange_manager || [])];
          console.log(ndt);
          for (const i of ndt) {
            for (const j of ru) {
              if(i.user?._id) {
                // console.log(this.userrulist[i.user?._id || ""]);
                udta.push({type: i.type, action: i.action, user: i.user?._id, reportingunit: j});
                // if((this.userrulist[i.user?._id || ""] || []).indexOf(j) > -1 || i.action === "remove") udta.push({type: i.type, action: i.action, user: i.user?._id, reportingunit: j});
              }
            }
          }
          newdata.data = udta;
        }else{
          newdata.type = "accesschange";
          let udta = [];
          let ndt = [...(this.userdatachange_submitter || []), ...(this.userdatachange_approver || []), ...(this.userdatachange_regional_approver || []), ...(this.userdatachange_manager || [])];
          for (const i of ndt) {
            if(i.user?._id) udta.push({type: i.type, action: i.action, user: i.user?._id, reportingunit: ru});
          }
          newdata.data = udta;
        }
        // let add = {};
        // let remove = {};
        // let usercngdt = {
        //   submitter: this.userdatachange_submitter,
        //   approver: this.userdatachange_approver,
        //   regional_approver: this.userdatachange_regional_approver,
        //   manager: this.userdatachange_manager,
        // }
        // for (const k in usercngdt) {
        //   if (Object.hasOwnProperty.call(usercngdt, k)) {
        //     const el = this["userdatachange_"+k];
        //     for (const i of el) {
        //       if(i.action === "remove"){
        //         if(i.user?._id){
        //           remove[k] = remove[k] || [];
        //           remove[k].push(i.user._id);
        //         }
        //       } else if(i.action === "add"){
        //         if(i.user?._id){
        //           add[k] = add[k] || [];
        //           add[k].push(i.user._id);
        //         }
        //       }
        //     }
        //   }
        // }
        // for (const k in add) {
        //   if (Object.hasOwnProperty.call(add, k)) {
        //     const el = add[k];
        //     if(el.length === 0) delete add[k];
        //   }
        // }
        // for (const k in remove) {
        //   if (Object.hasOwnProperty.call(remove, k)) {
        //     const el = remove[k];
        //     if(el.length === 0) delete remove[k];
        //   }
        // }
        // if(Object.keys(add).length > 0) newdata.adduser = add;
        // if(Object.keys(remove).length > 0) newdata.removeuser = remove;
      }
      else if(this.addeditrudata.__type === "file") {
        this.bulksubmitruloading = true;
        newdata.type = "fileaccesschange";
        newdata.bulk = true;
        // let ndt = [];
        // newdata.reportingunit = [];
        let udta = [];
        // console.log(this.bulksubmitrufiledata);
        for (const i of this.bulksubmitrufiledata) {
          // let td = {};
          // td.rucode = this.rucodeidmap[i.rucode];
          // newdata.reportingunit.push(td.rucode);
          if(Object.prototype.hasOwnProperty.call(i, "l_preparer_ids")) {
            for (const j of i.l_preparer_ids) udta.push({type: "submitter", action: "add", user: j, reportingunit: this.rucodeidmap[i.rucode]});
          }
          if(Object.prototype.hasOwnProperty.call(i, "l_approver_ids")) {
            for (const j of i.l_approver_ids) udta.push({type: "approver", action: "add", user: j, reportingunit: this.rucodeidmap[i.rucode]});
          }
          if(Object.prototype.hasOwnProperty.call(i, "r_approver_ids")) {
            for (const j of i.r_approver_ids) udta.push({type: "regional_approver", action: "add", user: j, reportingunit: this.rucodeidmap[i.rucode]});
          }
          if(Object.prototype.hasOwnProperty.call(i, "manager_ids")) {
            for (const j of i.manager_ids) udta.push({type: "manager", action: "add", user: j, reportingunit: this.rucodeidmap[i.rucode]});
          }
        }
        newdata.data = udta;
      }
      console.log(newdata);
      // else if(this.addeditrudata.__type === "file") {
      //   this.bulksubmitruloading = true;
      //   newdata.type = "fileaccesschange";
      //   newdata.bulk = true;
      //   let ndt = [];
      //   newdata.reportingunit = [];
      //   // console.log(this.bulksubmitrufiledata);
      //   for (const i of this.bulksubmitrufiledata) {
      //     let td = {};
      //     td.rucode = this.rucodeidmap[i.rucode];
      //     newdata.reportingunit.push(td.rucode);
      //     if(Object.prototype.hasOwnProperty.call(i, "l_preparer_ids")) td.preparer = i.l_preparer_ids || undefined;
      //     if(Object.prototype.hasOwnProperty.call(i, "l_approver_ids")) td.approver = i.l_approver_ids || undefined;
      //     if(Object.prototype.hasOwnProperty.call(i, "r_approver_ids")) td.regional_approver = i.r_approver_ids || undefined;
      //     if(Object.prototype.hasOwnProperty.call(i, "manager_ids")) td.manager = i.manager_ids || undefined;
      //     ndt.push(td);
      //   }
      //   newdata.users = ndt;
      // }
      this.axios.post("/v2/varianceanalysis/reportingunit/saveaccessupdateforapproval1", {data: newdata}).then(dt => {
        if(dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Update request raised for approval");
          this.addeditrudialog = false;
          this.bulksubmitrudialog = false;
        }else throw new Error (dt.data.message || "Error raising change request");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.addeditruloading = false;
        this.bulksubmitruloading = false;
        this.getPendingApprovalData();
      })
    },
    // getUserRUList(uid) {
    //   if(uid){
    //     this.addeditruloading = true;
    //     this.userrulist[uid] = [];
    //     let fltr = {user: uid};
    //     return this.axios.post("/v2/varianceanalysis/reportingunit/getuserrulist/", {filter: fltr}).then(dt => {
    //       if(dt.data.status === "success") {
    //         this.userrulist[uid] = (dt.data.data || [])[0].reportingunit || [];
    //       }
    //       else throw new Error ("Error reading user data");
    //     }).catch(err => {
    //       this.$store.commit("sbError", err.message || err || "Unknown error!");
    //       console.log(err);
    //     }).finally(() => {
    //       this.addeditruloading = false;
    //     })
    //   }
    // },
    getUsers(ruids, rucodes) {
      this.addeditruloading = true;
      this.userlist = {};
      let fltr = {}
      if(ruids) fltr = {reportingunit: ruids};
      else if(rucodes) fltr = {reportingunitcode: rucodes};
      return this.axios.post("/v2/varianceanalysis/reportingunit/getuserlist/", {filter: fltr}).then(dt => {
        if(dt.data.status === "success") {
          let ndt = dt.data.data[0] || {};
          this.userlistarr = ndt.users || [];
          let colors = this.$nova.getColors(this.userlistarr.length, 0.2);
          this.useremailidmap = {};
          for (let i = 0; i < this.userlistarr.length; i++) {
            const el = this.userlistarr[i];
            this.useremailidmap[el.email] = el._id;
            el.newdisplay = el.email;
            if(el.name) el.newdisplay + " - " + el.name;
            this.userlistarr[i].newdisplay = el.newdisplay;
            this.userlist[el._id] = el;
            this.userlist[el._id].color = colors[i];
          }
        }
        else throw new Error ("Error reading user data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.addeditruloading = false;
      })
    },
    // editData(v) {
    //   this.addeditrudialog=true;
    //   this.addeditrudata=this.$nova.deepCopyJson(v);
    //   this.addeditrudata.__originalsubmitter = [...(this.addeditrudata.submitter || [])];
    //   this.addeditrudata.__originalapprover = [...(this.addeditrudata.approver || [])];
    //   this.addeditrudata.__originalmanager = [...(this.addeditrudata.manager || [])];
    //   this.addeditruerror={};
    //   if(this.addeditrudata.control_thresholds_percent) this.addeditrudata.control_thresholds_percent = this.addeditrudata.control_thresholds_percent*100;
    //   if(this.addeditrudata.management_thresholds_percent) this.addeditrudata.management_thresholds_percent = this.addeditrudata.management_thresholds_percent*100;
    // },
    // updateUserAccessChange() {
    //   this.addeditruloading = true;
    //   let newdata = this.userdatachange || {};
    //   this.axios.post("/v2/varianceanalysis/reportingunit/updateuseraccesschange/"+this.addeditrudata._id, {data: [newdata]}).then(dt => {
    //     if(dt.data.status === "success") {
    //       this.$store.commit("sbSuccess", "Update request raised for approval");
    //       this.addeditrudialog = false;
    //     }else throw new Error (dt.data.message || "Error raising change request");
    //   }).catch(err => {
    //     this.$store.commit("sbError", err.message || err || "Unknown error!");
    //     console.log(err);
    //   }).finally(() => {
    //     this.addeditruloading = false;
    //   })
    // },
    // bulkUpdateAccess() {
    //   this.bulkchangeloading = true;
    //   let newdata = {type: this.addusertype, data: this.bulkchange};
    //   let fltr = {count: this.filtereddata.length};
    //   if(this.selectednetwork !== "all") fltr.network = this.selectednetwork;
    //   if(this.selectedregion !== "all") fltr.region = this.selectedregion;
    //   if(this.selectedcountry !== "all") fltr.country = this.selectedcountry;
    //   this.axios.post("/v2/varianceanalysis/reportingunit/updatebulkuseraccesschange/", {data: newdata, filter: fltr}).then(dt => {
    //     if(dt.data.status === "success") {
    //       this.$store.commit("sbSuccess", "Bulk update request raised for approval");
    //       this.bulkchangedialog = false;
    //     }else throw new Error (dt.data.message || "Error raising bulk change request");
    //   }).catch(err => {
    //     this.$store.commit("sbError", err.message || err || "Unknown error!");
    //     console.log(err);
    //   }).finally(() => {
    //     this.bulkchangeloading = false;
    //   })
    // },
    removeAccess(k, type) {
      this["userdatachange_"+type] = this["userdatachange_"+type] || [];
      this["userdatachange_"+type].push({action: 'remove', type: type, user: this.addeditrudata[type][k]});
      this.addeditrudata[type].splice(k,1);
    },
    addUser() {
      // console.log("called", this.addeditrudata);
      // if(this.addeditrudata.__type === "bulk"){
      //   this.getUserRUList(this.adduservalue);
      // }
      let userid = this.adduservalue;
      let type = this.addusertype;
      this["userdatachange_"+type] = this["userdatachange_"+type] || [];
      this["userdatachange_"+type].push({action: 'add', type: type, user: this.getCodeName([userid])[0]});
      this.addeditrudata.__changeadd = this.addeditrudata.__changeadd || [];
      this.addeditrudata.__changeadd.push(userid);
      this.addeditrudata[type] = this.addeditrudata[type] || [];
      this.addeditrudata[type].push({});
      this.addeditrudata[type].pop();
      this.addusertype = '';
      this.adduservalue = '';
      this.adduserdialog = false;
    },
    undoChange(id, k, type, action){
      if(action === "remove")this.addeditrudata[type].push(this.getCodeName([id])[0]);
      else if(action === "add"){
        let ak = (this.addeditrudata.__changeadd || []).indexOf(id);
        if(ak > -1) this.addeditrudata.__changeadd.splice(ak,1);
      }
      (this["userdatachange_"+type] || []).splice(k,1);
      if(this["userdatachange_"+type].length === 0) delete this["userdatachange_"+type];
      this.sanitizeChangeList();
    },
    sanitizeChangeList(){
      let alluserids = [...(this.addeditrudata["submitter"] || []).map(d => d._id), ...(this.addeditrudata["approver"] || []).map(d => d._id), ...(this.addeditrudata["regional_approver"] || []).map(d => d._id), ...(this.addeditrudata["manager"] || []).map(d => d._id)];
      this["userdatachange_"+"submitter"] = (this["userdatachange_"+"submitter"] || []).filter(dt => (alluserids.indexOf(dt.user?._id) === -1)?true:false);
      this["userdatachange_"+"approver"] = (this["userdatachange_"+"approver"] || []).filter(dt => (alluserids.indexOf(dt.user?._id) === -1)?true:false);
      this["userdatachange_"+"regional_approver"] = (this["userdatachange_"+"regional_approver"] || []).filter(dt => (alluserids.indexOf(dt.user?._id) === -1)?true:false);
      this["userdatachange_"+"manager"] = (this["userdatachange_"+"manager"] || []).filter(dt => (alluserids.indexOf(dt.user?._id) === -1)?true:false);
      this.addeditrudata.__changeadd = (this.addeditrudata.__changeadd || []).filter(dt => (alluserids.indexOf(dt) === -1)?true:false);
    },
    readBulkSubmitFile(key) {
      let file = this.bulksubmitrufile;
      if ((file || {}).name && (this.fileprocessing[key] || 0) === 0) {
        let headings = ['RU Code', 'Name of Reporting Unit', 'Local preparer(s)', 'Local Approver(s)', 'Regional Approver(s)', 'Manager(s)'];
        // console.log(file);
        this.bulksubmitruloading = true;
        this.fileprocessing[key] = 1;
        let uploaddata = [];
        let ext = ((file || {}).name || "").split(".").pop();
        Promise.resolve().then(() => {
          if (["xlsx", "xls", "xlsm", "xlsb"].indexOf(ext) === -1) throw new "Only xlsx,xls,xlsm,xlsb file extension supported";
          return this.$nova.readExcelFile(file, "TOP SHEET");
        }).then(dt => {
          if (JSON.stringify(dt[0] || []) === JSON.stringify(headings)) {
            uploaddata = dt;
            let rucodes = [];
            for (let i = 0; i < uploaddata.length; i++) {
              if (i > 0) {
                const el = uploaddata[i];
                if(el.join("") !== ""){
                  let rowdata = {"rucode": el[0], "runame": this.rucodenamemap[el[0]] || "NA"};
                  rucodes.push(rowdata.rucode);
                }
              }
            }
            return this.getUsers(undefined, rucodes);
          } else throw Error("Columns '"+headings.join("','")+"' are required in the same order");
        }).then(() => {
          // console.log(Object.keys(this.useremailidmap));
          let filedata = [];
          let errordata = [];
          this.bulksubmitrufileerrorflag = false;
          for (let i = 0; i < uploaddata.length; i++) {
            if (i > 0) {
              const el = uploaddata[i];
              if(el.join("") !== ""){
                let errors = {};
                let startindex = 2;
                let rowdata = {"rucode": el[0], "runame": this.rucodenamemap[el[0]] || "NA"};
                if (!this.rucodenamemap[rowdata.rucode]) errors["rucode"] = "RU Code not found";
                if (el[startindex+0]) {
                  let terrors = [];
                  let tids = [];
                  let temails = el[startindex+0].split(",");
                  for (const i of temails) {
                    // console.log(this.useremailidmap[i]);
                    if(this.useremailidmap[i]) {
                      tids.push(this.useremailidmap[i]);
                      terrors.push("")
                    }
                    else terrors.push("Email not found");
                  }
                  rowdata.l_preparer = temails;
                  rowdata.l_preparer_ids = tids;
                  if(terrors.join("") !== "") errors.l_preparer = terrors;
                }
                if (el[startindex+1]) {
                  let terrors = [];
                  let tids = [];
                  let temails = el[startindex+1].split(",");
                  for (const i of temails) {
                    if((rowdata.l_preparer || []).indexOf(i) > -1){
                      terrors.push("Local Approver and Local Preparer can't be same");
                    }
                    else if(this.useremailidmap[i]) {
                      tids.push(this.useremailidmap[i]);
                      terrors.push("")
                    }
                    else terrors.push("Email not found");
                  }
                  rowdata.l_approver = temails;
                  rowdata.l_approver_ids = tids;
                  if(terrors.join("") !== "") errors.l_approver = terrors;
                }
                if (el[startindex+2]) {
                  let terrors = [];
                  let tids = [];
                  let temails = el[startindex+2].split(",");
                  for (const i of temails) {
                    if((rowdata.l_preparer || []).indexOf(i) > -1){
                      terrors.push("Regional Approver and Local Preparer can't be same");
                    }
                    else if((rowdata.l_approver || []).indexOf(i) > -1){
                      terrors.push("Regional Approver and Local Approver can't be same");
                    }
                    else if(this.useremailidmap[i]) {
                      tids.push(this.useremailidmap[i]);
                      terrors.push("")
                    }
                    else terrors.push("Email not found");
                  }
                  rowdata.r_approver = temails;
                  rowdata.r_approver_ids = tids;
                  if(terrors.join("") !== "") errors.r_approver = terrors;
                }
                if (el[startindex+3]) {
                  let terrors = [];
                  let tids = [];
                  let temails = el[startindex+3].split(",");
                  for (const i of temails) {
                    if((rowdata.l_preparer || []).indexOf(i) > -1){
                      terrors.push("Manager and Local Preparer can't be same");
                    }
                    else if((rowdata.l_approver || []).indexOf(i) > -1){
                      terrors.push("Manager and Local Approver can't be same");
                    }
                    else if((rowdata.r_approver || []).indexOf(i) > -1){
                      terrors.push("Manager and Regional Approver can't be same");
                    }
                    else if(this.useremailidmap[i]) {
                      tids.push(this.useremailidmap[i]);
                      terrors.push("")
                    }
                    else terrors.push("Email not found");
                  }
                  rowdata.manager = temails;
                  rowdata.manager_ids = tids;
                  if(terrors.join("") !== "") errors.manager = terrors;
                }
                if (Object.keys(errors).length > 0) {
                  this.bulksubmitrufileerrorflag = true;
                }
                filedata.push(rowdata);
                errordata.push(errors);
              }
            }
          }
          this.bulksubmitrufiledata = filedata;
          this.bulksubmitrufileerror = errordata;
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.bulksubmitrufile = null;
          this.fileprocessing[key] = 0;
          this.bulksubmitruloading = false;
        });
      }
    },
  },
  computed: {
    filteredUserListArr: function(){
      let rdt = [];
      if(this.addusertype){
        for (const k in this.userlistarr) {
          if (Object.hasOwnProperty.call(this.userlistarr, k)) {
            const el = this.userlistarr[k];
            let pushflag = true;
            let remsubids = (this["userdatachange_"+"submitter"] || []).map(dt => dt.user?._id);
            let remappids = (this["userdatachange_"+"approver"] || []).map(dt => dt.user?._id);
            let remrappids = (this["userdatachange_"+"regional_approver"] || []).map(dt => dt.user?._id);
            let remmanids = (this["userdatachange_"+"manager"] || []).map(dt => dt.user?._id);
            if((this.addeditrudata["__original"+this.addusertype] || []).indexOf(el._id) > -1) pushflag = false;
            
            if((this.addeditrudata["submitterids"] || []).indexOf(el._id) > -1) pushflag = false;
            else if((this.addeditrudata["approverids"] || []).indexOf(el._id) > -1) pushflag = false;
            else if((this.addeditrudata["managerids"] || []).indexOf(el._id) > -1) pushflag = false;
            else if((this.addeditrudata["regional_approverids"] || []).indexOf(el._id) > -1) pushflag = false;
            
            if(this.addusertype === "submitter"){
              if(remappids.indexOf(el._id) > -1) pushflag = true;
              else if(remmanids.indexOf(el._id) > -1) pushflag = true;
              else if(remrappids.indexOf(el._id) > -1) pushflag = true;
            }else if(this.addusertype === "approver"){
              if(remsubids.indexOf(el._id) > -1) pushflag = true;
              else if(remmanids.indexOf(el._id) > -1) pushflag = true;
              else if(remrappids.indexOf(el._id) > -1) pushflag = true;
            }else if(this.addusertype === "regional_approver"){
              if(remsubids.indexOf(el._id) > -1) pushflag = true;
              else if(remmanids.indexOf(el._id) > -1) pushflag = true;
              else if(remappids.indexOf(el._id) > -1) pushflag = true;
            }else if(this.addusertype === "manager"){
              if(remsubids.indexOf(el._id) > -1) pushflag = true;
              else if(remappids.indexOf(el._id) > -1) pushflag = true;
              else if(remrappids.indexOf(el._id) > -1) pushflag = true;
            }
            if((this.addeditrudata.__changeadd || []).indexOf(el._id) > -1) pushflag = false;
            if(pushflag) rdt.push(el);
          }
        }
      }
      return rdt;
    }
  },
}
</script>
